import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class App extends Vue {
  public dialogShown = false;
  private width = 0;
  public currentItem = "tab-Home";
  public items: string[] = [
    "Home",
    "Corona",
    "Programm",
    "Essensbeiträge",
    "Geschenke",
    "Übernachtung",
  ];

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  }

  private handleResize() {
    this.width = window.innerWidth;
  }

  get computedWidth(): number {
    if (this.width > 825) {
      if (this.width * 0.55 > 826) {
        return this.width * 0.55;
      } else {
        return 826;
      }
    }
    return this.width;
  }

  public openLocation() {
    window.open(
      "https://www.google.de/maps/place/Haus+der+Begegnung+%22St.+Claret%22/@48.3133,10.1552713,17z/data=!3m1!4b1!4m5!3m4!1s0x4799592b24bac77f:0x5806115da64c29a8!8m2!3d48.313223!4d10.1574764",
      "_blank"
    );
  }
}
